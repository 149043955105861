import React from "react";
import Header from '../components/headerComponent';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import ArticleHomeComponent from '../components/Blog/articleHomeComponent';
import ArticleComponent from '../components/Blog/articleComponent';
import NewFooterComponent from '../components/newFooterComponent';
const BlogArticleAdministration = (props) =>{
    const {
      data: {
        wpPost: {
          seo,
          blogArticleSection: {
            blogArticleAuthor,
            blogArticleData,
            blogArticleReadingTime,
            blogArticleText,
            blogArticleTitle,
            blogArticleImage: {
              localFile: {
                childImageSharp: { gatsbyImageData: blogArticleImage },
              },
            },
            blogRecommendedArticleEmployeesImage: {
              localFile: {
                childImageSharp: {
                  gatsbyImageData: blogRecommendedArticleEmployeesImage,
                },
              },
            },
            blogRecommendedArticleStrategyImage: {
              localFile: {
                childImageSharp: {
                  gatsbyImageData: blogRecommendedArticleStrategyImage,
                },
              },
            },
            blogRecommendedArticleStudentsImage: {
              localFile: {
                childImageSharp: {
                  gatsbyImageData: blogRecommendedArticleStudentsImage,
                },
              },
            },
            blogArticleSectionTwoText,
            blogArticleSectionThreeText,
            blogArticleSecondImage: {
              localFile: {
                childImageSharp: { gatsbyImageData: blogArticleSecondImage },
              },
            },
            blogArticleThirdImage: {
              localFile: {
                childImageSharp: { gatsbyImageData: blogArticleThirdImage },
              },
            },
          },
        },
      },
      pageContext: { title },
    } = props;
    return (
      <Layout seo={seo} title={title}>
        <Header />
        <ArticleHomeComponent
          articleHomeTitle={blogArticleTitle}
          articleHomeData={blogArticleData}
          articleHomeAuthor={blogArticleAuthor}
          articleHomeTimeForReading={blogArticleReadingTime}
          articleImage={blogArticleImage}
          blogArticleStrategyImage={blogRecommendedArticleStrategyImage}
          blogArticleStudentsImage={blogRecommendedArticleStudentsImage}
          blogArticleStopSharingIdeasImage={
            blogRecommendedArticleEmployeesImage
          }
        />
        <ArticleComponent
          articleText={blogArticleText}
          blogArticleStrategyImage={blogRecommendedArticleStrategyImage}
          blogArticleStudentsImage={blogRecommendedArticleStudentsImage}
          blogArticleStopSharingIdeasImage={
            blogRecommendedArticleEmployeesImage
          }
          articleDescription={blogArticleSectionTwoText}
          articleSubDescription={blogArticleSectionThreeText}
          articleSecondImage={blogArticleSecondImage}
          articleThirdImage={blogArticleThirdImage}
          tagsName="Idea Management"
          categoriesName="Transformation"
          blogArticleTitle="How leaders can encourage their employees to share their ideas"
          blogArticleData="16 Jun 2022"
          blogArticleTime="3 min read"
          blogArticleTwoData="23 Mar 2022"
          blogArticleTwoTime="9 min read"
          blogArticleTwoTitle="From Strategy to Delivery - closing the gap"
          blogArticleThreeData="21 Jul 2022"
          blogArticleThreeTime="4 min read"
          blogArticleThreeTitle="Why idea sharing in universities promotes diversity and inclusion"
          firstArticleRecommendedLink="/blog/how-leaders-can-encourage-their-employees-to-share-their-ideas"
          SecondArticleRecommendedLink="/blog/from-strategy-to-delivery-closing-the-gap"
          thirdArticleRecommendedLink="/blog/why-idea-sharing-in-universities-promotes-diversity-and-inclusion"
        />
        <NewFooterComponent isProduct={true} />
      </Layout>
    );
}
export const query = graphql`
  query {
    wpPost(
      slug: {
        eq: "why-idea-sharing-in-universities-promotes-diversity-and-inclusion"
      }
    ) {
      seo {
        metaDesc
        title
      }
      blogArticleSection {
        blogArticleAuthor
        blogArticleData
        blogArticleReadingTime
        blogArticleText
        blogArticleTitle
        blogArticleImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        blogArticleImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        blogRecommendedArticleEmployeesImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        blogRecommendedArticleStrategyImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        blogRecommendedArticleStudentsImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        blogArticleSectionTwoText
        blogArticleSectionThreeText
        blogArticleSecondFore
        blogArticleSecondImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        blogArticleThirdImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
export default BlogArticleAdministration;